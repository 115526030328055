import React from 'react';

const Loader = () => {
  return (
    <>
        {/* <div className="preloader" style={{textAlign:'center'}}>
            <img class="img-fluid" src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif"  style={{height:'100px'}} /> 
        </div> */}
        <div  class="pageLoader" id="pageLoader"></div>
    </>
  )
}

export default Loader