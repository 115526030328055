import React from 'react';
import './Login.css';
import { useEffect, useState } from "react";
import {  BrowserRouter as Router, Link, Route, Routes, useNavigate } from 'react-router-dom';
import { apiUrl } from '../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admin from '../Admin';

const Login = () => {

	const [email,setEmail] = React.useState('');
    const [password,setPassword] = React.useState('');
	const [logged, setLogged] = useState(null);

	const navigate = useNavigate();

	const handleLogin = async () => {
        // alert(email);
        //console.warn("email,password", email, password);
        console.log(JSON.stringify({email,password}));

        let result = await fetch((apiUrl.concat('user/login')),{
            method:'post',
            body:JSON.stringify({email,password}),
            headers: {
            'Content-Type': 'application/json'
            }
        });

        result = await result.json();
        console.log(result);
    
        if(result.status == true){
            localStorage.setItem('authToken', result.jwt_token);
            localStorage.setItem('isAuthenticatedUser',"true");
			localStorage.setItem('name',result.data.userName);        
			// setLogged(Math.random());
			toast.success(result.message, {
                toastId: 'success1',
            });
			navigate('/')
          
                      
        } else if (result.status == false) {
            // setMessage(result.message)
			toast.error(result.message, {
				//  position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });

        }
    }

  return (
    <>
 		<ToastContainer />
  		<img className="wave" src="/img/wave.png"/>
		<div className="container login">
			<div className="img">
				<img src="/img/bg.svg"/>
			</div>
			<div className="login-content">
				<div className='login-form' >
					<img src="/img/holisollogo.jpeg"/>
					<h2 className="title">Welcome</h2>
					<div className="input-div one">
						<div className="i">
							<i className="fas fa-user"></i>
						</div>
						<div className="div">
							{/* <h5>Username</h5> */}
							<input type="text" className="input" name="email" placeholder='email' onChange={(e) => setEmail(e.target.value)}  value={email} />
						</div>
					</div>
					<div className="input-div pass">
						<div className="i"> 
							<i className="fas fa-lock"></i>
						</div>
					<div className="div">
						{/* <h5>Password</h5> */}
						<input type="password" className="input" name='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)}  value={password} />
					</div>
					</div>
					<Link href="#">Forgot Password?</Link>
					<button type="submit" className="btn-login" onClick={handleLogin}>Login</button>
				</div>
			</div>
		</div>
    </>
  )
}

export default Login