import logo from './logo.svg';
import './App.css';
import Admin from './Admin';
import Login from './auth/Login';
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Router, useNavigate } from "react-router-dom";
// import UnAuthPages from './auth/UnAuthPages';

function App() {

  const navigate = useNavigate();

  const isUserLogin = localStorage.getItem('isAuthenticatedUser');
  
    if(isUserLogin === "true"){
      return ( 
        <div>
          <Admin/>   
        </div>   
      )

    }


    return (
        <div>
          <Login/> 
          {/* <UnAuthPages/> */}
        </div>     
    );

    
  
}

export default App;
