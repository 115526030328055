import React, { useState, useEffect } from 'react'; 

import { apiUrl } from '../Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Carousel } from 'react-bootstrap';
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

const Header = () => {

    // const apiUrl    = process.env.REACT_APP_API_URL;
    const username  = localStorage.getItem('name');
    const navigate  = useNavigate();

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('name');
        localStorage.removeItem('isAuthenticatedUser');
        localStorage.removeItem('usrId');
        localStorage.removeItem('usrRole');
        navigate('/'); 
    };

    const onIdle = () => {
    
        localStorage.removeItem('authToken');
        localStorage.removeItem('name');
        localStorage.removeItem('isAuthenticatedUser');
        localStorage.removeItem('usrId');
        localStorage.removeItem('usrRole');
        navigate('/');
      //insert any custom logout logic here
    }
  
    const { getRemainingTime } = useIdleTimer({
      onIdle,
       timeout: 30 * 60 * 1000, //30 minute idle timeout
       // timeout: 30000
    })

    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    // Function to handle input change
    const handleChange = (event) => {

        //navigate('/searching');
        const value = event.target.value;
        setInputValue(value);

        if (value === '') {
            // Clear suggestions if input value is empty
            setSuggestions([]);
            return;
          }

        // Fetch suggestions based on input value (e.g., from an API)
        // For simplicity, let's consider a static list of suggestions
        const newSuggestions = [
            "Auto",
            "Retail",
            "FG",
            "RM",
            "NRP",
            "RM",
            "LS"
        ].filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
        );

        setSuggestions(newSuggestions);
    };

    // Function to handle suggestion click
    const handleSuggestionClick = (value) => {
        setInputValue(value);
        setSuggestions([]);
    };

    //auto suggetion end

    const spanStyle = {

        color: '#000000',
        height: '700px'
    }

    const divStyle = {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '700px'
    }


    //slideer code start here-------

    const usr_token = localStorage.getItem('authToken');

    const [SliderImage, setSliderImage] = React.useState([""]); 
     
    
   
    const getSliderData = async () => { 
        const lob = ''; 
        const project = ''; 
        const service = '';  
        const arr =[];
        
        try {
            fetch((apiUrl.concat('category/lob_list')), {
            method: "POST", 
            body:JSON.stringify({lob,project,service}),
            headers: {
                "Content-Type": "application/json",
                'Authorization' : 'Bearer '+ usr_token,
            },
            })
            .then((response) => response.json())
            .then((response) => {
          
                if(response.status == false){
                    navigate('/');
            //   alert("wrong user token");
                localStorage.removeItem('isAuthenticatedUser'); 
                // isAuthenticatedUser(false); 
                }else{ 
                let sliding = response.data; 
                 console.log("all data"+ response.solution);
                // const imageUrls = sliding.map(item => item.img_url);
                setSliderImage(sliding);
                // setSliderImage(sliding)
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
 
    useEffect(() => {
        getSliderData();
    }, []);

    const onValueChange = async (e) => {  
        try {
            navigate('/search');
        } catch (error) {
            console.log(error);
        }
      };
    return (
        <>
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid nav-bar bg-transparent">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
                    
                    <a className="navbar-brand d-flex align-items-center text-center">
                        <div className="icon p-2 me-2">
                            <img className="img-fluid" src="img/holisollogo.jpeg" alt="Icon" style={{ width: '120px' }} />
                        </div>
                        <h1 className="m-0 text-primary">Gallery</h1>
                    </a>
                  
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <div className="wrap">
                                <div className="search">
                                    <input type="text" onClick={onValueChange} className="searchTerm" placeholder="What are you looking for?" />
                                    <button type="submit" className="searchButton">
                                        <i className="fa fa-search"></i>
                                    </button>  
                                </div>

                                {suggestions.map((suggestion, index) => (
                                    <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </div>
                                    ))}
                            </div>

                            {/* <a href="index.html" className="nav-item nav-link active">Home</a>
                            <a href="about.html" className="nav-item nav-link">About</a> */}
                            {/* <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</a>
                                <div className="dropdown-menu rounded-0 m-0">
                                    <a href="property-list.html" className="dropdown-item">Property List</a>
                                    <a href="property-type.html" className="dropdown-item">Property Type</a>
                                    <a href="property-agent.html" className="dropdown-item">Property Agent</a>
                                </div>
                            </div> */}
                            <div className="nav-item dropdown">
                                <div className="icon3" style={{marginRight:'56px'}}>

                                    <img className="img-fluid" src="img/user.png" alt="Icon" /><br></br>

                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>{username}</strong></a>
                                    <div className="dropdown-menu ">
                                        <Link onClickCapture={logout} className="dropdown-item">Logout</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <a href="" className="btn btn-primary px-3 d-none d-lg-flex">Add Button</a> */}

                    </div>
                </nav>
            </div>
            {/* <!-- Navbar End --> */}

            {/* <!-- Header Start --> */}
            <div className="container-fluid header bg-white p-0">
                <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
                    <div className="col-md-6 p-5 mt-lg-5">
                        {/* <img className='img-fluid' src="/assets/images/carousel_1.jpg" alt="logo" /> */}
                        <h1 className="display-5 animated fadeIn mb-4">Unlock Efficiency, Embrace Sustainability</h1>
                        <p className="animated fadeIn mb-4 pb-2">Holisol Logistics - Your Returnable Packaging Partner</p>
                       {/* <a href="" className="btn btn-primary py-3 px-5 me-3 animated fadeIn">Get Started</a>*/}
                    </div>
                    <div className="col-md-6 animated fadeIn"  style={{ height: '600px' }}>
                      
                        <Carousel className="custom-carousel">
                        { SliderImage.map((data,index)=>(
                            

                            <Carousel.Item interval={1000} className="custom-img">
                            <NavLink to= {data.name?.toLowerCase()}  title={data.name + ' Solutions'}  >  <img src={data.img_url} alt="" text="First Slide" />
                                <Carousel.Caption>
                                <h3 style={{color:'#fff'}}>{data.name}</h3>
                              {/*}  <p style={{color:'#fff'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> {*/}
                                </Carousel.Caption></NavLink> 
                            </Carousel.Item>
                        ))}
                          
                        </Carousel>


                    </div>
                </div>
            </div>
            {/* <!-- Header End --> */}
        </>
    )
}

export default Header