import React, { useState, useEffect ,useRef}  from 'react';
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom'; 
import Select from "react-select"; 
import { apiUrl } from '../Api';
import {LOBList,ProductList,ServicesList} from '../apidata/getapi';
const Search = () => {

    const navigate  = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
  
    const usr_token = localStorage.getItem('authToken'); 
  
    const { lobListData }      = LOBList(); 
      // const { productListData }  = ProductList(); 
    const [productListData, setProductListData] = React.useState([""]);
     // const { serviceListData }  = ServicesList(); 
    const [serviceListData, setServiceListData] = React.useState([""]);
   
    //product dropdown listing code start--------------******************
      const getProductDropdownData = async () => {
        const arr = [];
      
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 
        console.log('exist lob data : ' + existLobName); 
  
        try {
        fetch((apiUrl.concat('/dropdown/product')), {   
            method: 'POST', 
            body:JSON.stringify({'lob' : existLobName}), 
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
        .then((response) =>
            response.json()
          )
          .then((response) => {  
           
           // console.log(result);
          //  if(result.status ==  true){
             let result = response.data;
              result?.map((data) => {
                  
                return arr.push({ value: data.id, label: data.value });
              });
              setProductListData(arr)
              
          //  }else if(result.status == false){
          //       navigate('/login')
          //  }
           
          });
        } catch (error) {
            console.log(error);
        }
  
  
      }
      useEffect(() => {
        getProductDropdownData();
      }, []);
  
    //product dropdown listing code end--------------- 
  
    //Service dropdown listing code start--------------******************
      const getServiceDropdownData = async () => {
        const arr = [];
      
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 
       
        let productname = sessionStorage.getItem("productnamemydash");
        let existProjectName = ""; 
        if(productname != '' && productname != null){
            let proName = JSON.parse(productname); 
            existProjectName= proName.value ;
        }  
          console.log('exist project data : ' + existProjectName); 
        try {
          fetch((apiUrl.concat('/dropdown/services')), {   
            method: 'POST', 
            body:JSON.stringify({'lob' : existLobName,'product' : existProjectName,}), 
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
          .then((response) =>
            response.json()
          )
          .then((response) => {  
            let result = response.data;
            console.log('service get '+result);
            result?.map((data) => {
                
                return arr.push({ value: data.id, label: data.value });
            });
            setServiceListData(arr)
          });
        } catch (error) {
          console.log(error);
        }
  
  
      }
      useEffect(() => {
        getServiceDropdownData();
      }, []);
    
    //service dropdown listing code end --------------- 
  
      let lobname = sessionStorage.getItem("lobnamemydash");
      let lobnamemydash = JSON.parse(lobname);
   
  
      let productname = sessionStorage.getItem("productnamemydash");
      let productnamemydash = JSON.parse(productname);
  
      let servicename = sessionStorage.getItem("servicenamemydash");
      let servicenamemydash = JSON.parse(servicename);
     
      const initialData = {
        lob_name     : lobnamemydash, 
        product_name : productnamemydash,
        service_name : servicenamemydash, 
      };
   
      const [dropdownData, setDropdownData] = useState(initialData);
  
      const handleLOBNameInput = (data) => { 
        sessionStorage.removeItem("lobnamemydash"); 
        sessionStorage.setItem("lobnamemydash", JSON.stringify(data));  
        setDropdownData((oldValue) => ({
          ...oldValue,
          lob_name: data,
        }));
        getleadCountData();
        //Clear  product and service dropdown
        sessionStorage.removeItem("productnamemydash"); 
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData({ 
            product_name: "", 
            service_name: "",
            
        }) 
        getProductDropdownData();
   
      };
  
      const handleProductInput = (data) => { 
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.setItem("productnamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            product_name: data,
        }));
        getleadCountData(); 
        //Clear  product and service dropdown 
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData({  
        service_name: "", 
        }) 
  
        getServiceDropdownData();
  
      };
  
       
      const handleServicesInput =(data) =>{
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.setItem("servicenamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          service_name: data,
         }));
         getleadCountData(); 
      }
  
       
      const clearFilter = () =>{
        sessionStorage.removeItem("lobnamemydash");
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.removeItem("servicenamemydash"); 
        
        setDropdownData({
            lob_name: "",
            product_name: "",
            service_name: "",
            
        }) 
         getleadCountData();
         getProductDropdownData();
         getServiceDropdownData();
      }
  
      const getleadCountData = async (e) => {
       
        console.log(JSON.stringify(dropdownData));
        
        try {
          console.log("api call");
        } catch (error) {
            console.log(error);
        }
    };
  
    const [SolutionData, setSolutionData] = React.useState([""]);  
    const getSolutions = async (e) => {

     

      navigate('/search');
     
      var lob = ''; 
      let project = ''; 
      let service = ''; 
   
      let lobname = sessionStorage.getItem("lobnamemydash"); 
      let productname = sessionStorage.getItem("productnamemydash");
      let servicename = sessionStorage.getItem("servicenamemydash");
  
      if(lobname != '' && lobname != null){
          let lobName = JSON.parse(lobname); 
          var lob = lobName.value ; 
      } 
      
      if(productname != '' && productname != null){
          let proName = JSON.parse(productname); 
          project= proName.value ;
      }  
      if(servicename != '' && servicename != null){
        let serName = JSON.parse(servicename); 
       // service= serName.value ;    //open for searching service dropdown
      }  
        
   
      try { 
          fetch((apiUrl.concat('/category/lob_list')), {
          method: "POST", 
          body:JSON.stringify({lob,project,service}),
          headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer '+ usr_token,
          },
        })
        .then((response) => response.json())
        .then((response) => {
         // console.log(response.status);
            if(response.status == false){ 
              setErrorMessage("No Results Found !");
              setSolutionData([])
                // navigate('/');
            }else{ 
              setErrorMessage("View Solution !");
              let lobdata = response.solution;  
              setSolutionData(lobdata)
              
            }
        });
      } catch (error) {
          console.log(error);
      }
    };
  
    
  
    function getExtension(filename) {
      return filename.split('.').pop()
    }
    
    return (
        <div>
            {/* <!-- Search Start --> */}
            <div className="container-fluid bg-primary mb-5 wow fadeIn" data-wow-delay="0.1s" >
                <div className="container">
                    <div className="row g-2">
                        <div className="col-md-10">
                            <div className="row g-2">
                            <div className="col-md-4">
                            
                            <label  className='text-white'  >Select Lob</label>
                               <Select 
                                    className="border-0 "
                                    classNamePrefix="select Lob"
                                    name="lob_name"
                                    options={lobListData}
                                    onChange={handleLOBNameInput}
                                    defaultValue={lobnamemydash}
                                    key={lobnamemydash}
                               /> 

                          
                            </div>
                            <div className="col-md-4">
                                <label   className='text-white' >Select Project</label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select Project"
                                    name="product_name"
                                    options={productListData}
                                    onChange={handleProductInput}
                                    defaultValue={productnamemydash}
                                    key={productnamemydash}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className='text-white' >Select Services</label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="service_name"
                                    options={serviceListData} 
                                    onChange={handleServicesInput}
                                    defaultValue={servicenamemydash}
                                    key={servicenamemydash}
                                /> 
                                </div>
                            </div>
                        </div>
                        <div className="col-md right">
 
                        <button type="button" title='  Search'  onClick={getSolutions}  className="searchButton" style={{height: '41px !important',marginTop:'15px'}}><span class="fa fa-search"></span></button> 
                            

                        </div>
                        <div className="col-md-2 right icon4 " style={{height: '48px',marginTop:'18px'}}>
                            <img className="img-fluid" onClick={clearFilter} src="img/filter.png"  alt="Icon" />
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Search End --> */}
        </div>
    )
}

export default Search