import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Index from "./Index";
import Search from "../pages/Search";
import ImgDetails from "../pages/ImgDetails";
import Lob from "../pages/Lob";
import Project from "../pages/Project"; 
import Service from "../pages/Service"; 
import TestPage from "../pages/TestPage";
import Loading from "../pages/Loading";
 

const Navpage = () => {
  return (
    <>
     <React.Fragment>
        <Routes>
          <Route path="/" element={<Index />} />   
          <Route path="/search" element={<Search />} />    
          <Route path="/details/:id/:type" element={<ImgDetails />} /> 
          <Route path="/test" element={<TestPage />} /> 

          <Route path="/:id/" element={<Lob />} /> 
          <Route path="/:id/:pid/*" element={<Project />} />
          <Route path="/:id/:pid/:sid" element={<Service />} /> 
          <Route path="/loading" element={<Loading />} />   

        </Routes>
    </React.Fragment>
    </>
  )
}

export default Navpage