import React, { useState } from 'react';

const Loading = () => {
    const [page, setPage] = useState(0);

    const handleResetPage = () => {
        setPage(0); // Set page to 0
    };

    const handleIncrementPage = () => {
        setPage(prevPage => prevPage + 9); // Increment page by 9
    };

    return (
        <div>
            <p>Page: {page}</p>
            <button onClick={handleResetPage}>Reset Page</button>
            <button onClick={handleIncrementPage}>Increment Page</button>
        </div>
    );
};

export default Loading;