import React, { useState, useEffect } from 'react'; 
import { Routes,Route, NavLink, useNavigate, Link,useParams } from 'react-router-dom';
import { apiUrl } from '../Api';
import Loader from '../loader/Loader';
// import PowerPointViewer from './PowerPointViewer';

function Gallery() { 
   

    let base64 = require('base-64'); 
    const usr_token = localStorage.getItem('authToken'); 
    const navigate  = useNavigate(); 

    const [SolutionData, setData]   = useState([]);   
    const [page, setPage]           = useState(0);
    const [loading, setLoading]     = useState(false);
    const [countData, setCountData] = useState(0);
    
    useEffect(() => {
        fetchData();
    }, []);
    
      const fetchData = async () => {

        setLoading(true);

        const lob = '';
        const project = ''; 
        const service = '';  
      
        try {

          fetch((apiUrl.concat('category/loadmoredata')), {
          method: "POST",  
          body:JSON.stringify({lob,project,service,page}),
          headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer '+ usr_token,
          },
        })
        .then((response) => response.json())
        .then((response) => {
  
          if(response.status == false){
              setLoading(false);
              localStorage.removeItem('isAuthenticatedUser'); 
              navigate('/');

          }else{ 

            setLoading(false);

            console.log(response);
             
            let newData = response.solution;
             
            setData(prevData => [...prevData, ...newData]);

            setPage(prevPage => prevPage + 9);

            const dataCount = response.solutionCount;
            setCountData(dataCount);
          }
      }); 
 
      } catch (error) {
        console.error('Error fetching data:', error);
      }  
    };

    const handleLoadMore = () => {
      fetchData();
    }; 

    useEffect(() => {
       
    },[countData] );
  
    const handleLinkClick = () => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };  
 

    function getExtension(filename) {
        return filename.split('.').pop()
      }
      
    return (

      <>
      {loading && (
        <>
        <Loader/>
        </>
      )}

        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" >
                    <h1 className="mb-3">All Solution </h1>
                   {/*} <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>*/}
                </div>
                <div className="row g-4">
                 
                 {SolutionData != '' && SolutionData.map((SolutionData,index)=> {
                  
                   let ext = getExtension(SolutionData.name);
                        return(
                          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s"  key={index}>
                          <div className="property-item rounded overflow-hidden">
                              <div className="position-relative overflow-hidden">
                                 <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}> 
                                   {/*}   <Link to={'/details/2'}>    {*/}
                                      {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                          <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                      } 
                                      { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx'|| ext == 'csv'  ) &&
                                          <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}#wdSlideId=3`} style={{height:'300px' ,width:'405px'  }} position="absolute" > </iframe>
                                          // <PowerPointViewer pptxUrl={SolutionData.ImgUrl[0]} />
                                          
                                          // <DocViewer
                                          //   documents= {SolutionData.ImgUrl}
                                          //   // initialActiveDocument={docs[1]}
                                          //   pluginRenderers={DocViewerRenderers}
                                          // />
                                       
                                      }
                                      { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                          <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}#wdSlideId=3`} style={{height:'300px' ,width:'405px'  }} position="absolute" > </iframe>
                                      }

                                      { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                      
                                      // <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(SolutionData.ImgUrl)}&embedded=true`} style={{height:'300px' ,width:'405px'  }}>
                                      // </iframe> 

                                     // <embed   src={SolutionData.ImgUrl}   type="application/pdf"  style={{height:'300px' ,width:'405px'  }}  />
                                      <iframe   src= {`${SolutionData.ImgUrl}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`} style={{height:'300px' ,width:'405px'  }}></iframe>

                                      }
                                  </Link>
                                     { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                      
                                      <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i className="fa fa-search"></i></a></div>
                                      }
                                       {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                      
                                      <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                      }
 
                              
                              </div> 
                              
                              <div className="p-4 pb-0"> 
                              <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick} className="d-block h5 mb-2" href=""><marquee behavior="scroll" direction="left" className="marquee"> {SolutionData.name} </marquee> </Link>
                                 {/*} <p><i className="fa fa-map-marker-alt text-primary me-2"></i>{SolutionData.name}</p> {*/}
                              </div>
                            
                          </div>
                      </div>
                        ) })}
                </div> 
            </div>
            <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                        

                    { countData   !=  SolutionData.length   && (
                            // Render the icon if the condition is met
                            <button  className='btn btn-primary' onClick={handleLoadMore}  >
                                Browse More Solution
                                  </button>
                          )}

 
                           </div>  
          
        </div>  

 
  </>
      
    );
  }
  
  export default Gallery;