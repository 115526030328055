import React, { useState, useEffect ,useRef}  from 'react'; 
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom';
import Select from "react-select"; 
import { apiUrl } from '../Api';
import {LOBList,ProductList,ServicesList,SupplierList,OemNamelist,Componentfamilylist,Componentnamelist,Proposedulpackaginglist} from '../apidata/getapi';
import Loader from '../loader/Loader';
import { ReactSearchAutocomplete } from "react-search-autocomplete";


const Search = () => {

    const username  = localStorage.getItem('name');
    let base64 = require('base-64');
    const navigate  = useNavigate(); 
    const usr_token = localStorage.getItem('authToken'); 
   

    //suggetion search ---------

    
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    useEffect(() => {
        // Fetch suggestions from API when input value changes
        const fetchSuggestions = async () => {
            const arr = [];
        try {
         console.log(inputValue);
            
          //fetch(('http://localhost:8580/gallery/dropdown/suggestionsearch'), {   
            fetch((apiUrl.concat('/dropdown/suggestionsearch')), {
                //  fetch((apiUrl.concat('/category/lob_list')), {             //category/lob_list for old data
                method: "POST", 
                // body:JSON.stringify({lob,project,service}),
                body:JSON.stringify({inputValue}),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : 'Bearer '+ usr_token,
                },
                })
                .then((response) => response.json())
                .then((response) => { 
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.value });
                    });
                    setSuggestions(arr)
                     
                });

        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
        };
    
        if (inputValue!== '') {
        fetchSuggestions();
        } else {
        setSuggestions([]);
        }
    }, [inputValue]);
    

       // Function to handle suggestion click
        const handleSuggestionClick = (value) => {
           console.log("mysearch "+ value)
            setInputValue(value);
            setSuggestions([]);
            return;
           // setInputValue(event.target.value);  
        };

           
        const handleSearch = async () => {
          var searchInput = inputValue;
             console.log("suggetion  search result api " + searchInput);
        } 
    
    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('name');
        localStorage.removeItem('isAuthenticatedUser');
        localStorage.removeItem('usrId');
        localStorage.removeItem('usrRole');
        navigate('/');
        
    };


    const [errorMessage, setErrorMessage] = useState('');
    
    const { lobListData }              = LOBList(); 
    const { supplierListData }         = SupplierList(); 
    const { oemListData }              = OemNamelist(); 
    const { componentFamilyData }      = Componentfamilylist(); 
    const { componentNameData }        = Componentnamelist(); 
    const { proposedulpackagingData }  = Proposedulpackaginglist(); 
    const [typeListData, setTypeListData]       = React.useState([""]);
    const [productListData, setProductListData] = React.useState([""]);
    const [serviceListData, setServiceListData] = React.useState([""]);

    // console.log('kkk',proposedulpackagingData);
    
 //type  dropdown listing code start--------------******************
    const getTypeDropdownData = async () => {
        const arr = [];
        arr.push({ value: '1', label:  'Approve' });
        arr.push({ value: '3', label:  'Reject' }); 
        arr.push({ value: '0', label:  'Pending' }); 
        console.log(arr);
        setTypeListData(arr);
    }
    useEffect(() => {
        getTypeDropdownData();
    }, []);
   
    
    //product dropdown listing code start--------------******************
      const getProductDropdownData = async () => {
        const arr = [];
      
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 
        console.log('exist lob data : ' + existLobName); 
  
        try {
        fetch((apiUrl.concat('dropdown/product')), {   
            method: 'POST', 
            body:JSON.stringify({'lob' : existLobName}), 
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
        .then((response) =>
            response.json()
          )
          .then((response) => {  
            let result = response.data;
           // console.log(result);
            // if(result.status ==  true){
              result?.map((data) => {
                  
                  return arr.push({ value: data.id, label: data.value });
              });
              setProductListData(arr)
            // }else  if(result.status == false){
            //   navigate('/login')
            // }
          });
        } catch (error) {
            console.log(error);
        }
  
  
      }
      useEffect(() => {
        getProductDropdownData();
      }, []);
  
    //product dropdown listing code end--------------- 
  
    //Service dropdown listing code start--------------******************
      const getServiceDropdownData = async () => {
        const arr = [];
      
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 
       
        let productname = sessionStorage.getItem("productnamemydash");
        let existProjectName = ""; 
        if(productname != '' && productname != null){
            let proName = JSON.parse(productname); 
            existProjectName= proName.value ;
        }  
          console.log('exist project data : ' + existProjectName); 
        try {
          fetch((apiUrl.concat('dropdown/services')), {   
            method: 'POST', 
            body:JSON.stringify({'lob' : existLobName,'product' : existProjectName,}), 
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
          .then((response) =>
            response.json()
          )
          .then((response) => {  
            let result = response.data;
            console.log('service get '+result);
            // if(result.status ==  true){
              result?.map((data) => {
                  
                  return arr.push({ value: data.id, label: data.value });
              });
              setServiceListData(arr)
            // }else  if(result.status == false){
            //   navigate('/login')
            // }

          });
        } catch (error) {
          console.log(error);
        }
  
  
      }
      useEffect(() => {
        getServiceDropdownData();
      }, []);
    
    //service dropdown listing code end --------------- 
  
      let lobname = sessionStorage.getItem("lobnamemydash");
      let lobnamemydash = JSON.parse(lobname); 

      console.log('now lob data '+ lobname);
  
      let productname = sessionStorage.getItem("productnamemydash");
      let productnamemydash = JSON.parse(productname);
  
      let servicename = sessionStorage.getItem("servicenamemydash");
      let servicenamemydash = JSON.parse(servicename);

      let suppliername = sessionStorage.getItem("suppliernamemydash");
      let suppliernamemydash = JSON.parse(suppliername);

      let oemname = sessionStorage.getItem("oemnamemydash");
      let oemnamemydash = JSON.parse(oemname);

      let componentFamily = sessionStorage.getItem("componentfamilydash");
      let componentfamilydash = JSON.parse(componentFamily);

      let componentName = sessionStorage.getItem("componentnamedash");
      let componentnamedash = JSON.parse(componentName);

      let proposedName = sessionStorage.getItem("proposednamedash");
      let proposednamedash = JSON.parse(proposedName);

      let typeName = sessionStorage.getItem("typenamedash");
      let typenamedash = JSON.parse(typeName);

      
      
      
      const initialData = {
        lob_name         : lobnamemydash, 
        product_name     : productnamemydash,
        service_name     : servicenamemydash, 
        supplier_name    : suppliernamemydash, 
        oem_name         : oemnamemydash,
        component_family : componentfamilydash, 
        component_name   : componentnamedash, 
        proposed_name    : proposednamedash, 
        type_name        : typenamedash, 

      };

 
   
      const [dropdownData, setDropdownData] = useState(initialData);
  
      const handleLOBNameInput = (data) => { 
        sessionStorage.removeItem("lobnamemydash"); 
        sessionStorage.setItem("lobnamemydash", JSON.stringify(data));  


        setDropdownData((oldValue) => ({
          ...oldValue,
          lob_name: data,
        }));
        getleadCountData();
        //Clear  product and service dropdown
        sessionStorage.removeItem("productnamemydash"); 
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData({ 
            product_name: "", 
            service_name: "",
            
        }) 
        getProductDropdownData();
   
      };
  
      const handleProductInput = (data) => { 
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.setItem("productnamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            product_name: data,
        }));
        getleadCountData(); 
        //Clear  product and service dropdown 
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData({  
        service_name: "", 
        }) 
  
        getServiceDropdownData();
  
      };
  
       
      const handleServicesInput =(data) =>{
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.setItem("servicenamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          service_name: data,
         }));
         getleadCountData(); 
      }

      const handleSupplierInput =(data) =>{
        sessionStorage.removeItem("suppliernamemydash");
        sessionStorage.setItem("suppliernamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          supplier_name: data,
         }));
       //  getleadCountData(); 
      }

      const handleOemInput =(data) =>{
        sessionStorage.removeItem("oemnamemydash");
        sessionStorage.setItem("oemnamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          oem_name: data,
         }));
       //  getleadCountData(); 
      }

      const handleComponentFInput =(data) =>{
        sessionStorage.removeItem("componentfamilydash");
        sessionStorage.setItem("componentfamilydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          component_family: data,
         }));
       //  getleadCountData(); 
      }


      const handleComponentNInput =(data) =>{
        sessionStorage.removeItem("componentnamedash");
        sessionStorage.setItem("componentnamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          component_name: data,
         }));
       //  getleadCountData(); 
      }

      
      const handleProposedInput =(data) =>{
        sessionStorage.removeItem("proposednamedash");
        sessionStorage.setItem("proposednamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          proposed_name: data,
         }));
       //  getleadCountData(); 
      }

      const handleTypeInput =(data) =>{
        sessionStorage.removeItem("typenamedash");
        sessionStorage.setItem("typenamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          type_name: data,
         }));
       //  getleadCountData(); 
      }

      
      
       
      const clearFilter = () =>{
        sessionStorage.removeItem("lobnamemydash");
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.removeItem("servicenamemydash"); 
        sessionStorage.removeItem("suppliernamemydash"); 
        sessionStorage.removeItem("oemnamemydash");  
        sessionStorage.removeItem("componentfamilydash"); 
        sessionStorage.removeItem("componentnamedash");
        sessionStorage.removeItem("proposednamedash"); 
        sessionStorage.removeItem("typenamedash"); 
        
        setDropdownData({
            lob_name          : "",
            product_name      : "",
            service_name      : "",
            supplier_name     : "",
            oem_name          : "",
            component_family  : "",
            component_name    : "",
            proposed_name     : "",
            type_name         : "",
            
        }) 
        window.location.reload();
         getSolutions();
         getProductDropdownData();
         getServiceDropdownData();
      }
  
      const getleadCountData = async (e) => {
       
        console.log(JSON.stringify(dropdownData));
        
        try {
          console.log("api call");
        } catch (error) {
          console.log(error);
        }
    };
   
    const [SolutionData, setSolutionData] = useState([]);   
    const [page, setPage] = useState(0);
    const [countData, setCountData] = useState(0);
    const [loading, setLoading] = useState(false); 
    const [fetchDataEnabled, setFetchDataEnabled] = useState(0); 
    const [suggestionSearchData, setSuggestionSeachData] = useState([]); 
    
    console.log('show data '+SolutionData.length);
    console.log('all Data '+countData);
      

   
    useEffect(() => {
      if (fetchDataEnabled==0) {
          console.log("Fetching data...");
         
           getSolutions();

          }
          else{
            console.log("no Fetching data...");
          }
      }, [fetchDataEnabled]);  

    const searchResult = () => {
      window.location.reload();
      setPage(0); 
      setPage(prevPage => 0); 
      console.log('count page -------------'+ page); 
     // return;
      setFetchDataEnabled(1);
      setPage(0); 
      setSolutionData([]);
      getSolutions();
    }; 
   
     
      useEffect(() => {
         
        }, [page]);  

 

    const getSolutions = async (e) => { 
     
      setLoading(true);
      var lob      = ''; 
      let project  = ''; 
      let service  = '';  
      let supplier = "";
      let oem      = "";
      let cfamily  = "";
      let cname    = "";
      let proposed = "";
      let type     = "";
      let leadId   = "";
      let leadtype = "";
       
   
      let lobname         = sessionStorage.getItem("lobnamemydash"); 
      let productname     = sessionStorage.getItem("productnamemydash");
      let servicename     = sessionStorage.getItem("servicenamemydash");

      let suppliername    = sessionStorage.getItem("suppliernamemydash");
      let oemname         = sessionStorage.getItem("oemnamemydash");
      let componentfamily = sessionStorage.getItem("componentfamilydash");
      let componentname   = sessionStorage.getItem("componentnamedash");
      let proposedname    = sessionStorage.getItem("proposednamedash");
      let typename        = sessionStorage.getItem("typenamedash");
  
      if(lobname != '' && lobname != null){
          let lobName = JSON.parse(lobname); 
          var lob = lobName.value ; 
      } 
      
      if(productname != '' && productname != null){
        let proName = JSON.parse(productname); 
        project= proName.value ;
      }  
      if(servicename != '' && servicename != null){
        let serName = JSON.parse(servicename); 
        service= serName.value ;    //open for searching service dropdown
      }  

      if(suppliername != '' && suppliername != null){
        let supVar = JSON.parse(suppliername); 
        supplier= supVar.value ;     
      }  
      if(oemname != '' && oemname != null){
        let oemVar = JSON.parse(oemname); 
        oem = oemVar.value ;    
      }  
      if(componentfamily != '' && componentfamily != null){
        let CFamilyVar = JSON.parse(componentfamily); 
        cfamily= CFamilyVar.value ;    
      }  
      if(componentname != '' && componentname != null){
        let CNameVar = JSON.parse(componentname); 
        cname = CNameVar.value ;    
      }  
      if(proposedname != '' && proposedname != null){
        let propVar = JSON.parse(proposedname); 
        proposed= propVar.label ;    
      }  
      if(typename != '' && typename != null){
        let typeVar = JSON.parse(typename); 
        type= typeVar.value ;    
      } 
      
      
      console.log(JSON.stringify({leadId,leadtype,lob,project,service,supplier,oem,cfamily,cname,proposed,type,page}));
       
      try { 
        

           //fetch(('http://localhost:8580/gallery/category/search'), {  
           fetch((apiUrl.concat('category/search')), {             //category/lob_list for old data
          method: "POST", 
         // body:JSON.stringify({lob,project,service}),
          body:JSON.stringify({leadId,leadtype,lob,project,service,supplier,oem,cfamily,cname,proposed,type,page}),
          headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer '+ usr_token,
          },
        })
        .then((response) => response.json())
        .then((response) => {
          setLoading(false);
           
            // console.log(response.status);
            if(response.status == false){ 
              setErrorMessage("No Results Found !");
              setSolutionData([])
               navigate('/');
            }else{ 
              setErrorMessage("View Solution !");
              let newData = response.solution;  
               
               setSolutionData(prevData => [...prevData, ...newData]); 
               
               const dataCount = response.solutionCount;
               setCountData(dataCount);
               setPage(prevPage => prevPage + 9); 
                
            }
        });
      } catch (error) {
          console.log(error);
      }
      
    };
  
    const handleLoadMore = () => {
      getSolutions();
    }; 

 
    const handleLinkClick = () => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };  
  
    function getExtension(filename) {
      return filename.split('.').pop()
    }

    const handleOnSearch = (string, results) => {
      console.log("onsearch");
      console.log(string, results);

      let inputValue = string;
      console.log(JSON.stringify({inputValue}));

      if(inputValue != ''){

        fetch(apiUrl.concat('dropdown/suggestionsearch'), {
          method: 'POST',
          body: JSON.stringify({inputValue}),
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ usr_token,
          }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => { 
            if (result.status == true) {
                setLoading(false);
                console.log(result);
                setSuggestionSeachData(result.data);

            }else if (result.status == false) {
                setLoading(false);
                setSuggestionSeachData([]);
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })

      }
    
    };

    // useEffect(() => {
    //     // handleOnSearch();
    // }, [suggestionSearchData]);  
  
    const handleOnHover = (result) => {
      console.log("onhover");
      console.log(result);
    };
  
    const handleOnSelect = (item) => {
      console.log("onselect");
      console.log(JSON.stringify({item}));
    };
  
    const handleOnFocus = () => {
      console.log("Focused");
    };
  
    const handleOnClear = () => {
      console.log("Cleared");
    };

 
  return (
   <>
      {loading && (
        <>
        <Loader/>
        </>
      )}    
     
        <div className="container-fluid nav-bar bg-transparent">
             
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
                    <Link to={'/'}>
                        <a  className="navbar-brand d-flex align-items-center text-center">
                            <div className="icon p-2 me-2">
                                <img className="img-fluid" src="img/holisollogo.jpeg" alt="Icon" style={{ width: '120px' }} />
                            </div>
                            <h1 className="m-0 text-primary">Gallery</h1>
                        </a>
                    </Link>
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <div className="wrap">
                                {/* <form onSubmit={handleSearchdata}> */}
                                  {/* <div className="search"> */}
                                      {/* <input type="text" className="searchTerm" 
                                        // value={inputValue}
                                        name='search_val'
                                        value={searchCreate.search_val}
                                        onChange={handleInputChange} 
                                        placeholder="What are you looking for?" /> */}
                                          <div className="searchable" id="search" style={{width:'300px', textAlign:'center', position:'relative',  }}> 
                                            <ReactSearchAutocomplete
                                                style={{width:'50%'}}
                                                items={suggestionSearchData}
                                                onSearch={handleOnSearch}
                                                onHover={handleOnHover}
                                                onSelect={handleOnSelect}
                                                onFocus={handleOnFocus}
                                                onClear={handleOnClear}
                                               styling={{ zIndex:999 }} // To display it on top of the search box below
                                                autoFocus
                                            />
                                          </div>

                                      {/* <button type="submit" className="searchButton">
                                          <i className="fa fa-search"></i>
                                      </button> */}
                                  {/* </div> */}
                                {/* </form> */}
                            </div>

                            {/* <a href="index.html" className="nav-item nav-link active">Home</a>
                            <a href="about.html" className="nav-item nav-link">About</a> */}
                            {/* <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</a>
                                <div className="dropdown-menu rounded-0 m-0">
                                    <a href="property-list.html" className="dropdown-item">Property List</a>
                                    <a href="property-type.html" className="dropdown-item">Property Type</a>
                                    <a href="property-agent.html" className="dropdown-item">Property Agent</a>
                                </div>
                            </div> */}
                             <div className="nav-item dropdown">
                                <div className="icon3" style={{marginRight:'56px'}}>

                                    <img className="img-fluid" src="../img/user.png" alt="Icon" /><br></br>

                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>{username}</strong></a>
                                    <div className="dropdown-menu ">
                                        <Link onClickCapture={logout} className="dropdown-item">Logout</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <a href="" className="btn btn-primary px-3 d-none d-lg-flex">Add Bsutton</a> */}

                    </div>
                </nav>
           
            <div className="container-fluid bg-primary mb-5 " id="fluid" data-wow-delay="0.1s" >
                <div className="container">
                    <div className="row g-2">
                        <div className="col-md-10">
                            <div className="row g-2">
                                <div className="col-md-2">
                                    <label  className='text-white'  >  Lob</label>
                                    <Select 
                                        className="border-0 "
                                        classNamePrefix="select Lob"
                                        name="lob_name"
                                        options={lobListData}
                                        defaultValue={lobnamemydash}
                                        key={lobnamemydash}
                                        onChange={handleLOBNameInput}
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label   className='text-white' >  Project</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select Project"
                                        name="product_name"
                                        options={productListData}
                                        onChange={handleProductInput}
                                        defaultValue={productnamemydash}
                                        key={productnamemydash}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >  Services</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="service_name"
                                        options={serviceListData} 
                                        onChange={handleServicesInput}
                                        defaultValue={servicenamemydash}
                                        key={servicenamemydash}
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >Supplier Name</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="supplier_name"
                                        options={supplierListData} 
                                        onChange={handleSupplierInput}
                                        defaultValue={suppliernamemydash}
                                        key={suppliernamemydash}
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >OEM Name</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="oem_name"
                                        options={oemListData}  
                                        onChange={handleOemInput}
                                        defaultValue={oemnamemydash}
                                        key={oemnamemydash}
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >Component Family</label>
                                    <Select
                                        className="basic-multi-select"
                                        fetchDataEnabledassNamePrefix="select"
                                        name="component_family" 
                                        options={componentFamilyData} 
                                        onChange={handleComponentFInput}
                                        defaultValue={componentfamilydash}
                                        key={componentfamilydash}
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >Component Name</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="component_name"
                                        options={componentNameData}
                                        onChange={handleComponentNInput}
                                        defaultValue={componentnamedash}
                                        key={componentnamedash}
                                               
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >Proposed UL Packaging</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="ul_packaging"
                                        options={proposedulpackagingData} 
                                        onChange={handleProposedInput}
                                        defaultValue={proposednamedash}
                                        key={proposednamedash}
                                        
                                    /> 
                                </div>
                                <div className="col-md-2">
                                    <label className='text-white' >Type</label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="type"
                                        options={typeListData} 
                                        onChange={handleTypeInput}
                                        defaultValue={typenamedash}
                                        key={typenamedash} 
                                    /> 
                                </div>
                                <div className="col-md-2 right">
                                  <button type="button" title='  Search'  onClick={searchResult}  className="searchButton" style={{height: '41px !important',marginTop:'15px', borderRadius:'5px'}}><span className="fa fa-search"></span></button> 
                                  <Link onClick={clearFilter} ><img className="img-fluid" style={{height: '43px', marginLeft: '20px', marginTop: '-12px'}} src="img/filter.png"  alt="Icon" /></Link>
                                </div>
                            </div>
                        </div>
                      
                        {/* <div className="col-md-1 right " style={{height: '43px',marginTop:'18px'}}>
                           
                        </div> */}

                    </div>
                </div>
            </div>
            </div>

            <div className="container-xxl py-5" style={{marginTop:'50px'}}>
            <div className="container">
                <div className="row g-0 gx-5 align-items-end">
                    <div className="col-lg-6">
                        <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                            <h1 className="mb-3">Solutions </h1>
                          
                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    <div id="tab-1" className="tab-pane fade show p-0 active">
                        <div className="row g-4"> 
                            {SolutionData != '' && SolutionData.map((SolutionData)=> { 
                            let ext = getExtension(SolutionData.name); 
                            return(
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="property-item rounded overflow-hidden">
                                            <div className="position-relative overflow-hidden">
                                              
                                               <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type}   onClick={handleLinkClick}> 
                                             {/*}   <Link to={'/details/2'}>    {*/}
                                                    {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                        <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                                    } 
                                                    { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx' ) &&
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                    }
                                                    { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}#wdSlideId=3`} style={{height:'300px' ,width:'405px'  }} position="absolute" > </iframe>
                                                    }

                                                    { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                                    
                                                    // <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(SolutionData.ImgUrl)}&embedded=true`} style={{height:'300px' ,width:'405px'  }}>
                                                    // </iframe> 

                                                   // <embed   src={SolutionData.ImgUrl}   type="application/pdf"  style={{height:'300px' ,width:'405px'  }}  />

                                                   <iframe   src= {`${SolutionData.ImgUrl}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`} style={{height:'300px' ,width:'405px'  }}></iframe>
                                                  }
                                              </Link>
                                                { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                  
                                                  <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i className="fa fa-search"></i></a></div>
                                                }
                                                  {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&

                                                  <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                                  }
                                                 
                                            {/*}   <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">Approve</div>
                                                <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">{ext}</div>{*/}
                                            </div>
                                            <div className="p-4 pb-0">
                                                {/*}   <h5 className="text-primary mb-3">$12,345</h5> {*/}
                                                <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type}  className="d-block h5 mb-2" href=""  onClick={handleLinkClick}><marquee behavior="scroll" direction="left">{SolutionData.name} </marquee></Link>
                                               {/*} <p><i className="fa fa-map-marker-alt text-primary me-2"></i>{SolutionData.name}</p> {*/}
                                            </div>
                                           {/*}    <div className="d-flex border-top">
                                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-ruler-combined text-primary me-2"></i>1000 Sqft</small>
                                                <small className="flex-fill text-center border-end py-2"><i className="fa fa-bed text-primary me-2"></i>3 Bed</small>
                                                <small className="flex-fill text-center py-2"><i className="fa fa-bath text-primary me-2"></i>2 Bath</small>
                                            </div>{*/}
                                        </div>
                                    </div>
                                ) })}
                          
                        </div>
                    </div>    
                </div>
            </div>
            <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                        

                    { countData   !=  SolutionData.length   && (
                            // Render the icon if the condition is met
                            <button  className='btn btn-primary' onClick={handleLoadMore}  >
                                Browse More Solution 
                                  </button>
                          )}

 
                           </div>  
        </div>
   </>
  )
}

export default Search