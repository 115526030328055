import React, { useState, useEffect } from 'react' 
import { apiUrl } from '../Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Carousel } from 'react-bootstrap';
import { Routes,Route, NavLink, useNavigate, Link ,useParams} from 'react-router-dom';
import Tophead from '../layout/Tophead' 
import Loader from '../loader/Loader';

const Project = () => {

    let base64 = require('base-64'); 
    const navigate  = useNavigate();
    const lobIds = useParams().id;
    const proIds = useParams().pid;
  

    const proName = {"value":proIds?.toUpperCase(),"label":proIds?.toUpperCase()};  
    const proNameString = JSON.stringify(proName); 
    // Store the string in sessionStorage 
    sessionStorage.setItem("productnamemydash", proNameString);
    

   useEffect(() => {
    document.title = lobIds +" | "+ proIds + " |   Solution  Gallery"
      }, [])
     
    //slideer code start here-------
   
    const usr_token = localStorage.getItem('authToken'); 
    const [SliderImage, setSliderImage] = React.useState([""]); 
    

    const [GetMessage,setMessage]=React.useState(); 
   
     
    const getSliderData = async () => { 
        setLoading(true)

        const lob = lobIds;
        const project = proIds; 
        const service = '';  
        const arr =[];
        
        try {
            fetch((apiUrl.concat('category/lob_list')), {
            method: "POST", 
            body:JSON.stringify({lob,project,service}),
            headers: {
                "Content-Type": "application/json",
                'Authorization' : 'Bearer '+ usr_token,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                setLoading(false)
          
               if(response.status == false){
                    setMessage("No Record Found of "+ lobIds);
                }else{ 
                    let sliding = response.data;  
                    setSliderImage(sliding);
                    setMessage("NO");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
   
  
    useEffect(() => {
        getSliderData();
    }, []);
    //code for solution-----------------

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    //solution data code start here-------------


    const [SolutionData, setData] = useState([]);   
    const [page, setPage] = useState(0);
    const [countData, setCountData] = useState(0);
    const [loading, setLoading] = useState(false);

    console.log('show data '+SolutionData.length);
    console.log('all Data '+countData);
     
    useEffect(() => {
      // Fetch initial data when the component mounts
      fetchData();
    }, []);
 
    const fetchData = async () => {  
        setLoading(true)

        const lob = lobIds;
        const project = proIds; 
        const service = '';  
        
        try {
            fetch((apiUrl.concat('category/loadmoredata')), {
            method: "POST",  
            body:JSON.stringify({lob,project,service,page}),
            headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer '+ usr_token,
            },
        })
        .then((response) => response.json())
        .then((response) => {
            setLoading(false)
       
            if(response.status == false){
                localStorage.removeItem('isAuthenticatedUser'); 
                navigate('/');
            }else{ 
                
                let newData = response.solution;
                
                setData(prevData => [...prevData, ...newData]);

                setPage(prevPage => prevPage + 9); 
                const dataCount = response.solutionCount;
                setCountData(dataCount);
                
            }
        }); 

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    useEffect(() => {
      
      
    },[countData]);
  
    const handleLoadMore = () => {
      fetchData();
    }; 
    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      };  

  return (
    <>
      {loading && (
        <>
        <Loader/>
        </>
      )}    
    <Tophead/>   
        {/* <!-- Header Slider Start --> */}
        {GetMessage!='NO' ? (
           
            
            <div className="container" style={{ paddingTop:'100px' }}> <h1> {GetMessage}</h1> </div>
          ) : (
            <>
             <div className="container-fluid header bg-white p-0">
                <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
                    <div className="col-md-6 p-5 mt-lg-5">
                        {/* <img className='img-fluid' src="/assets/images/carousel_1.jpg" alt="logo" /> */}
                        <h1 className="display-5 animated fadeIn mb-4">Unlock Efficiency, Embrace Sustainability</h1>
                        <p className="animated fadeIn mb-4 pb-2">Holisol Logistics - Your Returnable Packaging Partner</p>
                        
                    </div>
                    <div className="col-md-6 animated fadeIn"  style={{ height: '600px' }}>
                      
                        <Carousel className="custom-carousel">
                        {SliderImage.map((data,index)=>( 
                            <Carousel.Item interval={1000} className="custom-img">
                            <NavLink to= {data.name?.toLowerCase()}  title={data.name + ' Solutions'}  >  <img src={data.img_url} alt="" text="First Slide" style={{width:'1000px'}} />
                                <Carousel.Caption>
                                <h3 style={{color:'#fff'}}>{data.name}</h3>
                                {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                                </Carousel.Caption>
                            </NavLink> 
                            </Carousel.Item>
                        ))}
                          
                        </Carousel>
 
                    </div>
                </div>
            </div>
            {/* <!-- Header Slider End --> */}
            {/* solution gallery start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-0 gx-5 align-items-end">
                        <div className="col-lg-6">
                            <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                                <h1 className="mb-3">{lobIds?.toUpperCase()} | {proIds?.toUpperCase()}    Solution</h1>
                                {/*}  <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p> {*/}
                            </div>
                        </div> 
                    </div>
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
 
                            <div className="row g-4"> 
                                {SolutionData != '' && SolutionData.map((SolutionData)=> { 
                                let ext = getExtension(SolutionData.name); 
                                return(
                                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="property-item rounded overflow-hidden">
                                                <div className="position-relative overflow-hidden">
                                                <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}> 
                                                {/*}   <Link to={'/details/2'}>    {*/}
                                                        {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                            <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                                        } 
                                                        { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx' ) &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                        }
                                                         { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                        }

                                                        { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&  
                                                          //   <embed   src={SolutionData.ImgUrl}   type="application/pdf"  style={{height:'300px' ,width:'405px'  }}  />
                                                          <iframe   src= {`${SolutionData.ImgUrl}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`} style={{height:'300px' ,width:'405px'  }}></iframe>
                                                        }
                                                    </Link>
                                                    { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                      
                                                    <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i class="fa fa-search"></i></a></div>
                                                    }

                                                    {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&

                                                    <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                                    }
                                                  </div>
                                                <div className="p-4 pb-0">
                                                    
                                                <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}  className="d-block h5 mb-2" href=""><marquee behavior="scroll" direction="left"> {SolutionData.name} </marquee> </Link>
                                              
                                                </div>
                                            
                                            </div>
                                        </div>
                                    ) })}
                            
                            </div>
                        </div>    
                    </div>
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                    { countData   !=  SolutionData.length   && (
                            // Render the icon if the condition is met
                            <button  className='btn btn-primary' onClick={handleLoadMore}  >
                                Browse More Solution  
                                  </button>
                          )}
 
 
                           </div>  
                </div>
            </div>
             </>
        )}
 
      
    
           

            
    </>
  )
}

export default Project
