import React, { useState } from 'react';
 
import Tophead from '../layout/Tophead'
 
 const TestPage = () => {

 
    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
   return (
    <>
        <Tophead/>
        
        <div><marquee
      behavior="scroll"
      direction="left"
      style={isHovered ? { animationPlayState: 'paused' } : {}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      test
    </marquee></div>
        
        </>
    
   )
 }
 
 export default TestPage
 