import React, { useState, useEffect } from 'react'
import Header from './Header'
import Search from './Search'
import Gallery from './Gallery'
import Section from './Section'
import Client from './Client'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  BrowserRouter as Router, Link, Route, Routes, useNavigate } from 'react-router-dom';




const Index = () => {

    const navigate = useNavigate();
    const isUserLogin = localStorage.getItem('isAuthenticatedUser'); 
  if(isUserLogin == 'true'){
     navigate('/')
  }

  return (
    <>
        <ToastContainer />
        <Header/>
           {/*}  <Search/>{*/}
                    <Gallery/>
       {/*}   <Section/>
        <Client/>   {*/}
    </>
  )
}

export default Index