import React from 'react';
import Header from './layout/Header';
import Search from './layout/Search';
import Gallery from './layout/Gallery';
import Section from './layout/Section';
import Client from './layout/Client';
import Footer from './layout/Footer';
import Index from './layout/Index';
import Navpage from './layout/Navpage';


const Admin = () => {
  return (
    <div>
        
       <Navpage />  
       {/* <Index/> */}
       <Footer/>
       
    </div>
    
  )
}

export default Admin
