import React from 'react'

const Footer = () => {
  return (
    <>
    {/* <!-- Footer Start --> */}
        <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-white mb-4">Get In Touch</h5>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>A-1, Cariappa Marg, Sainik Farms, Gate No. 2
New Delhi 110062. India</p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>011-29555701</p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@holisollogistics.com</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href="https://twitter.com/holisollogistic"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/holisollogistics"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/channel/UC2q1hasaud1l831Q-e_mQSw"><i className="fab fa-youtube"></i></a>
                            <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/holisollogistics/"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-white mb-4">Quick Links</h5>
                        <a className="btn btn-link text-white-50" href="https://holisollogistics.com/about-holisol-logistics/">About Us</a>
                        <a className="btn btn-link text-white-50" href="https://holisollogistics.com/contact-us/">Contact Us</a>
                        <a className="btn btn-link text-white-50" href="https://holisollogistics.com/consulting/">Our Services</a>
                        <a className="btn btn-link text-white-50" href="https://holisollogistics.com/privacy-policy/">Privacy Policy</a>
                        <a className="btn btn-link text-white-50" href="https://holisollogistics.com/terms-and-conditions/">Terms & Condition</a>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-white mb-4">Photo Gallery</h5>
                        <div className="row g-2 pt-2">
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/finished-goods-packaging.jpg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/Finished-Goods-Packaging-2.jpg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/project-cargo-packaging.jpg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/Project-Cargo-Packaging.jpg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/spare-parts.jpg" alt=""/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded bg-light p-1" src="/img/spare-parts-and-packaging.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <h5 className="text-white mb-4">Newsletter</h5>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" >
                            <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-12 text-center">
                              2023 © holisollogistics.com  
                        </div>
                        {/* <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a href="">Home</a>
                                <a href="">Cookies</a>
                                <a href="">Help</a>
                                <a href="">FQAs</a>
                            </div>
                        </div>
                         */}
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Footer End --> */}


        {/* <!-- Back to Top --> */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  )
}

export default Footer