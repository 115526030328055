import React, { useState, useEffect } from 'react'; 
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom';
import { apiUrl } from '../Api';
 

const Tophead = () => {

    const navigate  = useNavigate();

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('name');
        localStorage.removeItem('isAuthenticatedUser');
        localStorage.removeItem('usrId');
        localStorage.removeItem('usrRole');
        navigate('/'); 
    };
 
  
    const onValueChange = async (e) => {  
        try {
            navigate('/search');
        } catch (error) {
            console.log(error);
        }
      };
 
     
    return (
        <>
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid nav-bar bg-transparent">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
                    
                <NavLink to={'/'} className="navbar-brand d-flex align-items-center text-center">
                        <div className="icon p-2 me-2">
                            <img className="img-fluid" src="/img/holisollogo.jpeg" alt="Icon" style={{ width: '120px' }} />
                        </div>
                        <h1 className="m-0 text-primary">Gallery</h1>
                    </NavLink>
                  
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <div className="wrap">
                                <div className="search">
                                    <input type="text" className="searchTerm" onChange={onValueChange} placeholder="What are you looking for?" />
                                    <button type="submit" className="searchButton">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>

                            {/* <a href="index.html" className="nav-item nav-link active">Home</a>
                            <a href="about.html" className="nav-item nav-link">About</a> */}
                            {/* <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</a>
                                <div className="dropdown-menu rounded-0 m-0">
                                    <a href="property-list.html" className="dropdown-item">Property List</a>
                                    <a href="property-type.html" className="dropdown-item">Property Type</a>
                                    <a href="property-agent.html" className="dropdown-item">Property Agent</a>
                                </div>
                            </div> */}
                            <div className="nav-item dropdown">
                                <div className="icon3 ">

                                    <img className="img-fluid" src="img/user.png" alt="Icon" /><br></br>

                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">USERS</a>
                                    <div className="dropdown-menu ">
                                        <Link onClickCapture={logout} className="dropdown-item">Logout</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <a href="" className="btn btn-primary px-3 d-none d-lg-flex">Add Button</a> */}

                    </div>
                </nav>
            </div>
            {/* <!-- Navbar End --> */}
 
        </>
    )
}

export default Tophead