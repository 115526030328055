import React, { useState, useEffect } from 'react' 
import { apiUrl } from '../Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Carousel } from 'react-bootstrap';
import { Routes,Route, NavLink, useNavigate, Link ,useParams} from 'react-router-dom';
import Loader from '../loader/Loader';

import Tophead from '../layout/Tophead'
  
const Lob = () => {

    let base64 = require('base-64'); 
    const navigate  = useNavigate();
    const lobIds = useParams().id;
    const proIds = useParams().pid;
    const serviceIds = useParams().sid;
    
    const serviceName = {"value":serviceIds?.toUpperCase(),"label":serviceIds?.toUpperCase()};  
    const serNameString = JSON.stringify(serviceName); 
    // Store the string in sessionStorage
    sessionStorage.setItem("servicenamemydash", serNameString);
    
    useEffect(() => {
        document.title = lobIds +" | "+ proIds + " |   Solution  Gallery"
          }, [])
     
    //slideer code start here-------
   
    const usr_token = localStorage.getItem('authToken');   
    const [GetMessage,setMessage]=React.useState(); 
  
    const [SolutionData, setData] = useState([]);   
    const [page, setPage] = useState(0);
    const [countData, setCountData] = useState(0);
    const [loading, setLoading] = useState(false);

    console.log('show data '+SolutionData.length);
    console.log('all Data '+countData);
   
     
    useEffect(() => {
      fetchData();
    }, []);
     
        const fetchData = async () => {
          setLoading(true)

          const lob = lobIds;
          const project = proIds; 
          const service = serviceIds; 
            
          fetch((apiUrl.concat('category/loadmoredata')), {
            method: "POST", 
            body:JSON.stringify({page}), 
            body:JSON.stringify({lob,project,service,page}),
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
          .then((response) => response.json())
          .then((response) => {
              setLoading(false)

              if(response.status == false){
                setMessage("No Record Found of "+ lobIds);
              }else{ 

                let newData = response.solution;
                setData(prevData => [...prevData, ...newData]);
                setPage(prevPage => prevPage + 9); 
                const dataCount = response.solutionCount;
                setCountData(dataCount);
                setMessage("NO");
              }
          }); 
         
        };


   
        useEffect(() => {
      
      
        },[countData]);
      
        const handleLoadMore = () => {
          fetchData();
        }; 
    
        
     
    function getExtension(filename) {
        return filename.split('.').pop()
      }

      const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      };  
     
  return (
    <>
      {loading && (
        <>
        <Loader/>
        </>
      )}  
    <Tophead/>   
        {/* <!-- Header Slider Start --> */}
        {GetMessage!='NO' ? (
           
            
            <div className="container" style={{ paddingTop:'100px' }}> <h1> {GetMessage}</h1> </div>
          ) : (
            <> 
            {/* <!-- Header Slider End --> */}
            {/* solution gallery start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-0 gx-5 align-items-end">
                        <div className="col-lg-6">
                            <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                            {/* <h1 className="mb-3">{lobIds?.toUpperCase()} | {proIds?.toUpperCase()}   |  {serviceIds?.toUpperCase()}   Solution</h1> */}
                                {/*}<p> rebum.</p> {*/}
                              <nav aria-label="breadcrumb">
                                  <ol className="breadcrumb">
                                    <li className="breadcrumb-item" aria-current="page"> <Link className="breadcrumb-item" style={{fontSize:'30px'}} >{lobIds?.toUpperCase()} </Link></li>
                                    <li className="breadcrumb-item" aria-current="page"> <Link className="breadcrumb-item" style={{fontSize:'30px'}}>{proIds?.toUpperCase()} </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"> <Link className="breadcrumb-item active" style={{fontSize:'30px'}}>{serviceIds?.toUpperCase()} </Link></li>
                                  </ol>
                              </nav>
                            
                            </div>
                        </div> 
                    </div>
                   
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
 
                            <div className="row g-4"> 
                                {SolutionData != '' && SolutionData.map((SolutionData)=> { 
                                let ext = getExtension(SolutionData.name); 
                                return(
                                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="property-item rounded overflow-hidden">
                                                <div className="position-relative overflow-hidden">
                                                <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}> 
                                                {/*}   <Link to={'/details/2'}>    {*/}
                                                        {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                            <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                                        } 
                                                        { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx' ) &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                        }
                                                        { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                        }

                                                        { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&  
                                                          //   <embed   src={SolutionData.ImgUrl}   type="application/pdf"  style={{height:'300px' ,width:'405px'  }}  />
                                                          <iframe   src= {`${SolutionData.ImgUrl}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`} style={{height:'300px' ,width:'405px'  }}></iframe>
                                                        }
                                                    </Link>
                                                    { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                      
                                                    <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i class="fa fa-search"></i></a></div>
                                                    }
                                                      {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&

                                                        <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                                        }
                                                  </div>
                                                <div className="p-4 pb-0"> 
                                                <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type}  onClick={handleLinkClick} className="d-block h5 mb-2" href=""><marquee behavior="scroll" direction="left"> {SolutionData.name} </marquee></Link>
                                              
                                                </div>
                                            
                                            </div>
                                        </div>
                                    ) })}
                            
                            </div>
                        </div>    
                    </div>
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                    { countData   !=  SolutionData.length   && (
                            // Render the icon if the condition is met
                            <button  className='btn btn-primary' onClick={handleLoadMore}  >
                                Browse More Solution   {SolutionData.length} - {countData}
                                  </button>
                          )} 
                           </div>  
                </div>
            </div>
             </>
        )}
 
      
    
           

            
    </>
  )
}

export default Lob
